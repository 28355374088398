/** @jsx jsx */
import { css } from "@emotion/react";
import BgSection2 from "../images/LP-teamsix/bg1.jpg";
import BgSection3 from "../images/LP-teamsix/bg-section-3.svg";
import BgSection6 from "../images/LP-teamsix/bg-section-6.svg";
import escolaAutoralMobile from "../../assets/images/LP-kingdom/uma-escola-autoral-mobile.png";
import ImgSection from "../../assets/images/LP-kingdom/quero-meu-filho-kd.png";
export const abAgency = css`
  /* background: url(${BgSection2}) no-repeat center center / cover; */
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  img {
    width: 90%;
    @media (max-width: 500px) {
      width: 100%;
      margin-top: 24px;
    }
  }

  .app-buttons {
    margin-top: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px) {
      margin-top: 56px;
    }

    .buttons {
      border-radius: 32px;
      background-color: #6c63ff;
      width: 730px;
      height: 224px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #f9f9f9;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 500px) {
        p {
          padding: 0 36px;
          margin-bottom: 24px;
        }
        width: 343px;
        font-size: 32px;
        height: auto;
        padding: 56px 0px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .div-buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 24px;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
  }
  .title-content {
    h1 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      color: #232323;
      font-family: "montserrat", sans-serif;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #868686;
      max-width: 580px;
    }
    @media (max-width: 500px) {
      margin-top: 56px;
      /* flex-direction: column-reverse; */
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 36px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const escolaPresente = css`
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow: hidden;
  /* margin: 150px 0px 0px 0px; */
  img {
      width: 90%;
      @media (max-width: 500px) {
        width: 100%;
      }
    }

  @media (max-width: 500px) {
    margin-top: 0px;
    overflow: hidden;
    .contentGift {
      overflow: hidden;
      padding-left: 0px;
      padding-right: 0px;
    }
    h1{
      text-align: center;
    }
  }
  .title-content {
    h1 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;  
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      color: #232323;
      font-family: "montserrat", sans-serif;
      @media (max-width: 850px) {
        /* width: 100%; */
      }  
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #868686;
      max-width: 580px;
    }
    @media (max-width: 500px) {
      margin-top: 56px;
      /* flex-direction: column-reverse; */
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 36px;
        width: 100%;
        text-align: center;
      }
      p {
        text-align: center;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  .btn-section {
    margin-top: 56px;
    font-family: "montserrat", sans-serif;
    a {
      border-radius: 56px;
      padding: 11.5px 24px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: none;
      letter-spacing: none;
    }
    a:first-of-type {
      margin-right: 16px;
      background: #FFA300;
      padding: 11.5px 24px;
      color: #3F3F3F;
      :hover {
        background: #5315FF;
        text-decoration: none;
        color: #FFFFFF;
      }
      :active {
        background: #000721;
        color: #FFFFFF
      }
    }
    a:nth-of-type(2) {
      margin-right: 16px;
      background: #5315FF;
      padding: 11.5px 24px;
      color: #ffffff;
      :hover {
        background: #F4643D;
        text-decoration: none;
        color: #FFFFFF
      }
      :active {
        background: #062871;
        color: #FFFFFF
      }
    }
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 56px;
      a:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const ensinoIntencional = css`
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow: hidden;
  .conatainerTeaching{
    padding-right: 0px;
    padding-left:  0px;
  }
  img {
    width: 90%;
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
  }
  .title-content {
    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      color: #232323;
      width: 100%;
      max-width: 450px;
      font-family: "montserrat", sans-serif;
      @media (max-width: 850px) {
        width: 100%;
        margin-top: 0px;
      }
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #868686;
      width: 100%;
      max-width: 450px;
    }
    p:not(:last-of-type) {
      margin-bottom: 16px;
    }
    @media (max-width: 500px) {
      margin-top: 56px;
      /* flex-direction: column-reverse; */
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 36px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: flex;
        overflow: hidden;
        justify-content: center;
      }
    }
  }

  .btn-section {

    .educacao_infantil{
      background: #FFA300;
    }
    margin-top: 56px;
    font-family: "montserrat", sans-serif;
    a {
      border-radius: 56px;
      padding: 11.5px 24px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: none;
      letter-spacing: none;
    }
    a:first-of-type {
      margin-right: 16px;
      background: #FFA300;
      padding: 11.5px 24px;
      color: #3F3F3F;
      :hover {
        background: #FFA124;
        text-decoration: none;
        color: #F5F5F5;
      }
      :active {
        background: #000721;
        color: #FFFFFF
      }
    }
    a:nth-of-type(2) {
      margin-right: 16px;
      background: blue;
      padding: 11.5px 24px;
      color: #FFFFFF;
      :hover {
        background: #F4643D;
        text-decoration: none;
        color: #FFFFFF
      }
      :active {
        background: #062871;
        color: #FFFFFF
      }
    }
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 11.5px 32px;
      padding-bottom: 56px;
      gap: 10px;
      a:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @media (min-width: 905px) {
      scroll-margin-top: 200px;
    }
    @media (max-width: 905px) {
      scroll-margin-bottom: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const ctaSectionConheca = css`
  /* background: url(${BgSection3}) no-repeat center center / cover; */
  background-color: #6f8263;
  padding: 70px 0;
  text-align: center;
  h2 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    width: 100%;
    max-width: 730px;
    margin: auto;
    font-weight: 600;
    @media (max-width: 850px) {
      width: 85%;
    }
  }
  p {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-variation-settings: "wght" 400;
    line-height: 150%;
    color: #f9f9f9;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #ff7843;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const ctaSectionFacaParte = css`
  max-width: 100%;
  padding: 160px 0px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  object-fit: contain ;
  font-family: "montserrat", sans-serif;
  background-image: url(${ImgSection});
  @media (max-width: 500px){
    margin: 0px;
    padding: 200px 0;
    object-fit: fill ;
  }
  div:first-of-type {
    z-index: 2;
    position: relative;
  }

  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    margin-bottom: 32px;
    margin-bottom: 16px;
    color: #ffffff;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  a {
    border-radius: 56px;
    background: #5315FF;
    padding: 11.5px 24px;
    color: #F5F5F5;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: none;
    :hover {
    background: #F4643D;
    text-decoration: none;
    color: #F5F5F5;
    }
    :active {
      background: #062871;
    }
  }
`;

export const ctaMaisTempoParaOBem = css`
  padding: 220px 0;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "montserrat", sans-serif;
  .div-align {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div:first-of-type {
    z-index: 2;
    position: relative;
  }
  h1 {
    color: #FFF;
    text-align: center;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 100px */
    letter-spacing: -5px;
  }
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    margin-bottom: 16px;
    color: #ffffff;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 40px;
    width: 100%;
    max-width: 538px;
    @media (max-width: 850px) {
      width: 328px;
    }
  }
  a {
    border-radius: 56px;
    background: #5315FF;
    padding: 11.5px 24px;
    color: #F5F5F5;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: none;
    :hover {
    background: #F4643D;
    text-decoration: none;
    color: #F5F5F5;
    }
    :active {
      background: #062871;
    }
  }
`;

export const ctaAtividadeFisica = css`
  padding: 220px 0;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "montserrat", sans-serif;
  .div-align {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div:first-of-type {
    z-index: 2;
    position: relative;
  }
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    margin-bottom: 16px;
    color: #ffffff;
    width: 100%;
    max-width: 730px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 40px;
    width: 100%;
    max-width: 538px;
    @media (max-width: 850px) {
      width: 328px;
    }
  }
  a {
    border-radius: 56px;
    background: #5315FF;
    padding: 11.5px 24px;
    color: #F5F5F5;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: none;
    :hover {
    background: #F4643D;
    text-decoration: none;
    color: #F5F5F5;
    }
    :active {
      background: #062871;
    }
  }
`;

export const EscolaSemCantina = css`
  /* background: url(${BgSection2}) no-repeat center center / cover; */
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 0;

  img {
    width: 90%;
    @media (max-width: 500px) {
      width: 100%;
      margin-top: 24px;
    }
  }

  .app-buttons {
    margin-top: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px) {
      margin-top: 56px;
    }

    .buttons {
      border-radius: 32px;
      background-color: #6c63ff;
      width: 730px;
      height: 224px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #f9f9f9;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 500px) {
        p {
          padding: 0 36px;
          margin-bottom: 24px;
        }
        width: 343px;
        font-size: 32px;
        height: auto;
        padding: 56px 0px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .div-buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 24px;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
  }
  .title-content {
    h1 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      color: #232323;
      font-family: "montserrat", sans-serif;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #868686;
      max-width: 580px;
    }
    @media (max-width: 500px) {
      margin-top: 0px;
      /* flex-direction: column-reverse; */
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 36px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
    padding: 0px;
    img {
      margin-top: 0px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const EscolaBiligue = css`
  /* background: url(${BgSection2}) no-repeat center center / cover; */
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 0px;
  
  img {
    width: 90%;
    @media (max-width: 500px) {
      width: 100%;
      margin-top: 24px;
    }
  }

  .app-buttons {
    margin-top: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px) {
      margin-top: 56px;
    }

    .buttons {
      border-radius: 32px;
      background-color: #6c63ff;
      width: 730px;
      height: 224px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #f9f9f9;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 500px) {
        p {
          padding: 0 36px;
          margin-bottom: 24px;
        }
        width: 343px;
        font-size: 32px;
        height: auto;
        padding: 56px 0px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .div-buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 24px;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
  }
  .title-content {
    h1 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      color: #232323;
      font-family: "montserrat", sans-serif;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #868686;
      max-width: 580px;
    }
    @media (max-width: 500px) {
      margin-top: 0px;
      /* flex-direction: column-reverse; */
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 36px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
    padding-top: 90px;
    padding-bottom: 0px;
    img {
      margin-top: 0px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;
export const desenvolvimentoSegurancaAfeto = css`
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  .content-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 90px;
  }

  img {
    width: 100%;
    height: 100%;
    @media (max-width: 500px) {
      display: none;
    }
  }

  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
  }
  .title-content {
    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 24px;
      color: #232323;
      width: 100%;
      max-width: 650px;
      font-family: "montserrat", sans-serif;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    h3 {
      color: #5315FF;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 40px;
      font-family: "montserrat", sans-serif;
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #868686;
      width: 100%;
      max-width: 610px;
    }
    @media (max-width: 500px) {
      /* margin-top: 56px; */
      /* flex-direction: column-reverse; */
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 36px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: none;
      }
    }
  }

  .btn-section {

    .educacao_infantil {
      background: #FFA300;
    }
    margin-top: 56px;
    font-family: "montserrat", sans-serif;
    a {
      border-radius: 56px;
      padding: 11.5px 24px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: none;
      letter-spacing: none;
    }
    a:first-of-type {
      margin-right: 16px;
      background: #FFA300;
      padding: 11.5px 24px;
      color: #3F3F3F;
      :hover {
        background: #FFA124;
        text-decoration: none;
        color: #F5F5F5;
      }
      :active {
        background: #000721;
        color: #FFFFFF
      }
    }
    a:nth-of-type(2) {
      margin-right: 16px;
      background: blue;
      padding: 11.5px 24px;
      color: #FFFFFF;
      :hover {
        background: #F4643D;
        text-decoration: none;
        color: #FFFFFF
      }
      :active {
        background: #062871;
        color: #FFFFFF
      }
    }
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      /* padding: 11.5px 70.5px; */
      /* padding-bottom: 56px; */
      gap: 10px;
      a:first-of-type {
        margin-bottom: 16px;
      }
    }
}

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`;

export const HeaderSection = css`
  padding-top: 200px;
  padding-bottom: 80px;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "montserrat", sans-serif;
  h1 {
    color: #FFF;
    text-align: center;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 100px */
    letter-spacing: -5px;
    @media (max-width: 500px) {
      font-family: Montserrat;
      text-align: left;
      font-size: 36px;
      font-style: normal;
      letter-spacing: -1.8px;
      margin-left: 28px;
    }
  }
  @media (max-width: 850px) {
    padding-top: 330px;
    padding-bottom: 0px;
  }
`;

export const ctaSectionEscolaAutoral = css`
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "montserrat", sans-serif;
  a {
      border-radius: 56px;
      padding: 11.5px 24px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: none;
      letter-spacing: none;
      background: blue;
  color: #FFFFFF;
  :hover {
    background: #F4643D;
    text-decoration: none;
    color: #FFFFFF
  }
  :active {
    background: #062871;
    color: #FFFFFF
  }
    }
  
  @media (max-width: 900px) {
      padding: 0;
    }
  
  div:first-of-type {
    z-index: 2;
    position: relative;
  }

  .desktop {
    @media (max-width: 900px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      padding: 142px 16px;
      height: fit-content;
      background-image: url(${escolaAutoralMobile});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  h2 {
    color: #ffffff;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    margin-bottom: 40px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    color: #DEDEDE;
    margin-bottom: 0px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  .contet-box {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`;

export const ctaSectionAgende = css`
  background: #ff7843;
  padding: 90px 0;
  text-align: center;
  h2 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    width: 100%;
    max-width: 730px;
    margin: auto;
    margin-bottom: 48px;
    span {
      color: #2f382b;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-width: 350px;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #f9f9f9;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const carreiraMilitarSection = css`
  background-color: #2f382b;
  padding: 90px 0;
  h2 {
    font-size: 36px;
    font-style: normal;
    line-height: 120%;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 32px;
    width: 100%;
    max-width: 500px;
    span {
      color: #2f382b;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #ff7843;
    display: flex;
    align-items: flex-start;
    img {
      margin-right: 8px;
    }
    :nth-child(n) {
      margin-bottom: 40px;
    }
  }
  p {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #f9f9f9;
    width: 100%;
    max-width: 610px;
    margin: auto;
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #f9f9f9;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const footerSection = css`
  background: #2C10A0;
  padding-top: 112px;
  padding-bottom: 96px;
  font-family: "montserrat", sans-serif;
  img {
    margin-bottom: 24px;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    span {
      font-weight: 600;
    }
  }
  a {
    img {
      width: 24px;
    }
  }
  .footer_desktop {

    .title {
      color: #FFF;
      font-size: 18px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.9px;
    }

    .link_desktop {
      white-space: nowrap;
      color: #E3E1E4;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
    }

    .link_desktop:hover {
      text-decoration: underline !important;
    }

    .col_one_container {
      display: flex;
      gap: 19px;
    }
    .segmentos_desktop {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .social_media_icons {
      display: flex;
      gap: 8px;
      a {
        height: 24px;
      }
    }
    
    @media (max-width: 768px) {
      display: none;
    }
  }

  .footer_mobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding-inline: 48px;
      .logo_text{
        p {
          max-width: 242px;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
        }
      }
      .menu {
        display: flex;
        justify-content: space-between;
        .home {
          color:#FFF;
          font-size: 18px;
          font-weight: 600;
          line-height: 120%;
          letter-spacing: -0.9px;
        }
        .segmentos{
          display: flex;
          flex-direction: column;
          gap: 8px;
          h3 {
            color:#FFF;
            font-size: 18px;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: -0.9px;
          }
          a {
            color:#FFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
          }
        }

        .segmentos_container {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
        .links{
          display: flex;
          flex-direction: column;
          gap: 24px;
          a {
            color:#FFF;
            font-size: 18px;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: -0.9px;
          }
        }
      }
    }
    .social_media {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-block: 61px;
      span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.9px;
      }
      img {
        margin: 0;
      }
      div {
        display: flex;
        gap: 16px;
      }
    }
  }
`;

export const formulariosContatos = css`
  background: #ffffff;
  padding: 0;
  font-family: "montserrat", sans-serif;
  padding-bottom: 130px;
  .box-content-1 {
    border-radius: 56px;
    background: #F5F5F5;
    padding: 109px 55px 46px 55px;
    h2 {
      color: #3F3F3F;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      @media (max-width: 850px) {
        width: 100%;
        font-size: 30px;
      }
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #7E7E7E;
      margin-bottom: 40px;
    }
    @media (max-width: 850px) {
      padding: 53px 16px;
      margin-bottom: 40px;
    }
  }
  .box-content-2 {
    border-radius: 56px;
    background: #F5F5F5;
    padding: 46px 55px 46px 55px;
    h2 {
      color: #3F3F3F;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 36px */
      letter-spacing: -1.8px;
      margin-bottom: 40px;
      @media (max-width: 850px) {
        width: 100%;
        font-size: 30px;
      }
    }
    h3 {
      color: #5315FF;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 36px */
      letter-spacing: -1.5px;
      margin-bottom: 24px;
      @media (max-width: 850px) {
        width: 100%;
        font-size: 18px;
      }
    }
    p {
      font-family: "montserrat", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #7E7E7E;
      margin-bottom: 40px;
    }
    @media (max-width: 850px) {
      padding: 53px 16px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    border-radius: 16px;

    button {
      align-self: flex-start;
      align-items: center;
      border-radius: 56px;
      background: #5315FF;
      border: none;
      color: #ffffff;
      display: flex;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      padding: 12px 32px;
      transition: .3s;
      @media (max-width: 768px) {
        align-self: flex-start;
      }
      &:hover{
        background-color: #F4643D;
        color: #ffffff;
      }
    }
    input {
      font-family: "montserrat", sans-serif;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid #333333;
      width: 100%;
      height: 35px;
      position: relative;
      padding: 0 12px;
      z-index: 1;
      margin-bottom: 8px;
      color: #3F3F3F;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; 

      &::placeholder {
        font-size: 16px;
        color: #3F3F3F;
        font-weight: 700;
        line-height: 16px;
        margin-top: 0;
      }
      &:focus {
        border-bottom: 1px solid #333333;
        outline: none;
      }
    }
    textarea {
      border: none;
      border-radius: 8px;
      width: 100%;

      height: 54px;
      position: relative;

      &::placeholder {
        font-size: 18px;
        color: #375f92;
        font-weight: 700;
        line-height: 120%;
      }

      &::placeholder {
        position: absolute;
        top: 16px;
        left: 0;
      }
    }
    #mensagem {
      height: 92px;
    }

    select{
      border:none;
      border-radius: 4px;
      font-size: 16px;
      padding: 8px;
      font-weight: 700;
      color: #2f382b;
    }
  }
  .btn-submit {
    display: flex;
    justify-content: end;
    @media (max-width: 850px) {
      justify-content: center;
      margin-top: 56px;
    }
  }
`;

export const ondeEstamos = css`
  background: #5315FF;
  padding: 114px 0;
  font-family: "montserrat", sans-serif;
  h2 {
    color: #ffff;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    margin-bottom: 83px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 40px;
  }
`;

export const card_image = css`
  max-width: 350px;
  width: 100%;
`
export const doMaternalAoFundamentalSection = css`
  padding: 130px 0;
  font-family: "montserrat", sans-serif;
  background: #F5F5F5;
  .competence{
    margin-left: 16px;
    margin-right: 16px;
   @media (max-width: 500px){
    width: 400px;
   }
  }
 .content{
  
  @media (max-width: 500px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
 }
  h2 {
    color: #232323;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 36px */
    letter-spacing: -1.8px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h3 {
    color: #232323;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -1.2px;
    margin: 24px 24px 16px 16px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    color: #868686;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  a {
    color: #232323;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    align-items: center;
    display: flex;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #F4643D;
      text-decoration: none;
    }
    :active {
      color: #000F3A;
      text-decoration: none;
    }
  }
  .box-content {
    max-width: 350px;
    border-radius: 32px;
    background: #ffffff;
    img:first {
      width: 100%;
    }
    @media (max-width: 850px) {
      margin-bottom: 28px;
    }

  }
  ul {
    margin: 40px 16px 28px 16px;
    padding: 0px;
    li {
      color: #868686;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
`;

export const conhecaOFundadorSection = css`
  background: url(${BgSection6}) no-repeat center center / cover;
  padding: 120px 0;
  @media (max-width: 768px) {
    padding: 15px 0;
    img {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  h2 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 33.6px */
    text-transform: uppercase;
    color: #2f382b;
    margin-bottom: 16px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h3 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: #2f382b;
    margin-bottom: 16px;
    position: relative;
    z-index: 0;
    display: inline;
    padding: 0 8px;
    &::before {
      content: "";
      display: block;
      width: 100%;
      background-color: #ff7843;
      height: 10px;
      position: absolute;
      bottom: 6px;
      z-index: -1;
    }
    &::after {
      content: "";
      display: block;
      width: 341px;
      background-color: #ff7843;
      height: 10px;
      position: absolute;
      top: 28px;
      z-index: -1;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h4 {
    font-size: 14px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%;
    text-transform: uppercase;
    color: #2f382b;
    margin-bottom: 40px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  p {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-variation-settings: "wght" 400;
    line-height: 150%;
    color: #9a9a9a;
    margin: 16px 0;

    &:nth-of-type(2) {
      margin-bottom: 32px;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #ff7843;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
    @media (max-width: 850px) {
      display: table;
      margin: 0 auto 32px;
    }
  }
`;

export const teamSix = css`
  /* background: url(${BgSection2}) no-repeat center center / cover; */
  background-color: #2f382b;
  padding: 146px 0;
  @media (max-width: 850px) {
    padding: 96px 0;
  }
  h2 {
    font-size: 76px;
    font-style: normal;
    line-height: 100%;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: 24px;
    text-align: center;
    span {
      color: #2f382b;
      font-variation-settings: "wght" 800;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
  }
  h5 {
    font-size: 28px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 33.6px */
    text-transform: uppercase;
    color: #f9f9f9;
    width: 100%;
    max-width: 480px;
    margin: auto;
    text-align: center;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #ff7843;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
    :nth-child(n) {
      margin-bottom: 40px;
    }
  }
  p {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #f9f9f9;
    width: 100%;
    max-width: 610px;
    margin: auto;
    margin-bottom: 40px;
    span {
      font-weight: 600;
    }
  }
  a {
    color: #2f382b;
    border-radius: 4px;
    background: #f9f9f9;
    font-size: 16px;
    font-style: normal;
    font-variation-settings: "wght" 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    padding: 12px 32px;
  }
`;

export const content = css`
  /* padding-top: 200px;
  padding-bottom:200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  @media (max-width: 500px) {
      padding-top: 50px;
      padding-bottom:50px;
  }
  
  ul {
    padding-left: 0px;
    li {
      color: #3F3F3F;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 20px;
      font-family: "montserrat", sans-serif;
      width: 100%;
      max-width: 430px;
      img {
        width: 10px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
  }
  
  .btn-section {
    .agende_sua_visita{
      background-color: #F4643D;
    }
    display: flex;
    gap: 16px;
    a {
      border-radius: 56px;
      background: #5315FF;
      padding: 11.5px 24px;
      /* color: #F5F5F5 !important; */
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: none;
      font-family: "montserrat", sans-serif;
      letter-spacing: none;
      :hover {
      background: #F4643D;
      text-decoration: none;
      color: #F5F5F5;
      }
      :active {
        background: #062871;
      }
    }
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  div {
    &:first-of-type {
      max-width: 590px;
    }
  }
`;

export const aprovados = css`
  display: flex;
  justify-content: space-between;
  max-width: 540px;

  @media (max-width: 850px) {
    flex-wrap: wrap;
  }

  div {
    h3 {
      font-family: "geom-graphic", sans-serif;
      font-size: 48px;
      font-weight: 600;
      color: #ffffff;
      position: relative;
      z-index: 1;
      margin: 0;
    }

    p {
      display: block;
      position: relative;
      font-family: "montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #ffffff;
      line-height: 6px;
      &:before {
        display: block;
        background-color: #ff7843;
        width: 100%;
        height: 10px;
        content: "";
        position: absolute;
        top: -18px;
        border-radius: 2px;
      }
    }
  }
`;

export const info = css`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  max-width: 520px;

  div {
    &:first-of-type {
      max-width: 320px;
    }
  }
`;
export const footerMenu = css`
  display: flex;
  flex-direction: column;
  color: #f9f9f9;

  ul {
    margin: 0;
    padding: 0;
    margin-right: 50px;
    li {
      &:first-of-type {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.9px;
      }
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #E3E1E4;
      margin-bottom: 16px;
      font-family: "montserrat", sans-serif;
      a {
        color: #ffffff;
      }
    }
  }
`;
export const footerBottom = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 70px 0;
  background: #2C10A0;
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #ffffff;
    margin-top: 24px;
    font-family: "Montserrat", sans-serif;
    span {
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    div {
      justify-content: center;
    }
    .row div {
      text-align: center;
    }
    img {
      margin: 32px 0;
    }
  }
`;

export const footerTop = css`
  padding: 50px 0 45px;

  h2 {
    @media (max-width: 768px) {
      margin-bottom: 32px;
    }
  }

  p{
    a{
      color: #ffffff;
    }
  }
`;

export const year = css`
  position: relative;
  margin-bottom: 32px;
  height: 84%;
  overflow: hidden;

  p {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 42px;
  }

  .year2003 {
    transition: all 0.7s;
    width: 370px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .year2024 {
    transition: all 0.8s;
    width: 370px;
    position: absolute;
    top: 0;
    left: 380px;
  }

  &.hide {
    .year2003 {
      left: -380px;
    }
    .year2024 {
      left: 0px;
    }
  }
`;
export const next = css`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #ff7843;
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.3s;
  &:hover {
    color: #ffffff;
    span {
      margin-left: 12px;
    }
  }

  span {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 8px;
    transition: 0.2s;
    img {
      width: 14px;
    }
  }
`;
export const mapSection = css`
  margin: 180px 0 0;

  @media (max-width: 850px) {
    margin: 80px 0 0;
  }
`;

export const mapaDoBrasilMobile = css`
  text-align: center;
  .mapMobile {
    width: 90%;
  }

  .mobile2003 {
    margin: 24px 0;
  }
  .mobile2024 {
    margin: 24px 0;
  }

  p {
    max-width: 90%;
  }
`;

export const mapaDoBrasil = css`
  position: relative;

  .torre {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    img {
      transform: scaleY(0);
    }
  }

  .t1 {
    top: 93px;
    left: 52px;
  }
  .t2 {
    top: 35px;
    left: 102px;
  }
  .t3 {
    top: -19px;
    left: 324px;
  }
  .t4 {
    top: 124px;
    right: 113px;
    z-index: 3;
  }
  .t5 {
    top: 73px;
    right: 56px;
  }
  .t6 {
    bottom: 164px;
    right: 146px;
  }
  .t7 {
    bottom: 210px;
    right: 240px;
    z-index: 3;
  }
  .t8 {
    bottom: 133px;
    right: 230px;
  }
  .t9 {
    bottom: 150px;
    right: 325px;
  }
  .t10 {
    bottom: 226px;
    left: 263px;
  }
  .t11 {
    top: 102px;
    left: 148px;
  }
  .t12 {
    top: 76px;
    right: 132px;
  }
  .t13 {
    top: 44px;
    right: 172px;
  }
  .t14 {
    top: -27px;
    left: 174px;
  }
  .t15 {
    top: 45px;
    left: 311px;
  }
  .t16 {
    top: 83px;
    right: 222px;
  }

  .estado {
    position: absolute;

    img {
      transition: all 0.4s;
      opacity: 0;
      &.show {
        opacity: 1;
      }
    }
  }

  .al {
    top: 137px;
    right: 62px;
  }
  .am {
    top: 34px;
    left: 1px;
  }
  .ac {
    top: 130px;
    left: 0;
  }
  .ap {
    top: 13px;
    left: 302px;
  }
  .ba {
    top: 143px;
    right: 96px;
  }
  .ce {
    top: 86px;
    right: 93px;
  }
  .es {
    bottom: 160px;
    right: 132px;
  }
  .go {
    bottom: 179px;
    right: 230px;
  }
  .mg {
    bottom: 143px;
    right: 137px;
  }
  .ms {
    bottom: 132px;
    right: 310px;
  }
  .mt {
    bottom: 193px;
    left: 193px;
  }
  .pr {
    bottom: 105px;
    right: 265px;
  }
  .rj {
    bottom: 138px;
    right: 152px;
  }
  .ro {
    bottom: 237px;
    left: 110px;
  }
  .rr {
    top: 2px;
    left: 143px;
  }
  .rs {
    bottom: 33px;
    right: 291px;
    z-index: 99;
    img {
      opacity: 1 !important;
    }
  }
  .sc {
    bottom: 78px;
    right: 272px;
  }
  .se {
    top: 146px;
    right: 80px;
  }
  .sp {
    bottom: 119px;
    right: 205px;
  }
  .to {
    top: 110px;
    right: 229px;
  }
  .rn {
    top: 99px;
    right: 60px;
  }
  .pa {
    top: 30px;
    right: 232px;
  }
  .ma {
    top: 68px;
    right: 166px;
  }
  .pi {
    top: 85px;
    right: 144px;
  }
  .pe {
    top: 124px;
    right: 57px;
  }
  .pb {
    top: 112px;
    right: 55px;
  }
  .df {
    top: 199px;
    right: 253px;
  }

  .up {
    -webkit-animation: scale-up-ver-bottom 0.6s
      cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-ver-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  @-webkit-keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
  }
  @keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
  }
`;

export const animaMapaMobile = css`
  position: relative;
  width: 360px;
  margin: 0 auto;

  .mapaBase {
    width: 100%;
  }

  .torre {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    width: 35px;
    img {
      transform: scaleY(0);
      max-width: 100%;
    }
  }

  .t1 {
    top: 23px;
    left: 56px;
  }
  .t2 {
    top: 48px;
    left: 220px;
  }
  .t3 {
    top: 39px;
    left: 324px;
  }
  .t4 {
    top: 124px;
    right: 113px;
    z-index: 3;
  }
  .t5 {
    top: 104px;
    right: 68px;
  }
  .t6 {
    bottom: 176px;
    right: 77px;
  }
  .t7 {
    bottom: 216px;
    right: 224px;
    z-index: 3;
  }
  .t8 {
    bottom: 140px;
    right: 236px;
  }
  .t9 {
    bottom: 150px;
    right: 296px;
  }
  .t10 {
    bottom: 161px;
    left: 273px;
  }
  .t11 {
    top: 117px;
    left: 159px;
  }
  .t12 {
    top: 86px;
    right: 115px;
  }
  .t13 {
    top: 27px;
    right: 149px;
  }
  .t14 {
    top: -10px;
    left: 186px;
  }
  .t15 {
    top: 71px;
    left: 284px;
  }
  .t16 {
    top: 75px;
    right: 182px;
  }

  .estado {
    position: absolute;

    img {
      width: 100%;
      transition: all 0.4s;
      opacity: 0;
      &.show {
        opacity: 1;
      }
    }
  }

  .al {
    top: 70px;
    right: 13px;
    width: 26px;
  }
  .am {
    top: 19px;
    left: 0px;
    width: 160px;
  }
  .ac {
    top: 68px;
    left: 0;
    width: 64px;
  }
  .ap {
    top: 5px;
    left: 172px;
    width: 44px;
  }
  .ba {
    top: 82px;
    right: 33px;
    width: 81px;
  }
  .ce {
    top: 45.5px;
    right: 31px;
    width: 37px;
  }
  .es {
    bottom: 87px;
    right: 53px;
    width: 19px;
  }
  .go {
    bottom: 102.2px;
    right: 109px;
    width: 65px;
  }
  .mg {
    bottom: 82px;
    right: 56px;
    width: 98px;
  }
  .ms {
    bottom: 75.5px;
    right: 155px;
    width: 65px;
  }
  .mt {
    bottom: 109.8px;
    left: 110px;
    width: 103px;
  }
  .pr {
    bottom: 58px;
    right: 128.7px;
    width: 58px;
  }
  .rj {
    bottom: 72px;
    right: 64px;
    width: 36px;
  }
  .ro {
    bottom: 135.2px;
    left: 63px;
    width: 62.7px;
  }
  .rr {
    top: 1px;
    left: 82px;
    width: 52.5px;
  }
  .rs {
    bottom: 19px;
    right: 144px;
    width: 70.3px;
    z-index: 99;
    img {
      opacity: 1 !important;
    }

  }
  .sc {
    bottom: 40px;
    right: 133px;
    width: 45.6px;
  }
  .se {
    top: 76px;
    right: 24px;
    width: 15.6px;
  }
  .sp {
    bottom: 68.2px;
    right: 95px;
    width: 75.6px;
  }
  .to {
    top: 63px;
    right: 108px;
    width: 42.6px;
  }
  .rn {
    top: 49px;
    right: 11px;
    width: 30.6px;
  }
  .pa {
    top: 17.3px;
    right: 110.3px;
    width: 114.3px;
  }
  .ma {
    top: 39px;
    right: 73px;
    width: 58.3px;
  }
  .pi {
    top: 48px;
    right: 59.9px;
    width: 49.6px;
  }
  .pe {
    top: 63.3px;
    right: 10px;
    width: 55.6px;
  }
  .pb {
    top: 56px;
    right: 9px;
    width: 34.6px;
  }
  .df {
    top: 106px;
    right: 122px;
    width: 8px;
  }

  .up {
    -webkit-animation: scale-up-ver-bottom 0.6s
      cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-ver-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  @-webkit-keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
  }
  @keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
  }
`;

export const desktop = css`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const mobile = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;


export const success = css`
background-color: #FFC208;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 86px;
padding: 120px 0;
img {
  display: flex;
  margin: auto;
}
h1 {
  font-family: "montserrat", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 56px */
  letter-spacing: -2.8px;
  color: #3F3F3F;
  width: 100%;
  max-width: 540px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 32px;
  }
}
p {
  color: #3F3F3F;
  font-family: "montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    text-align: center;
  }
}
`;

export const notfound = css`
background-color: #2F382B;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 454px;
margin-top: 56px;
h1{
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 56px;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 32px;
  }
}
p{
  color: #ffffff;
  font-size: 18px;
  @media (max-width: 768px) {
    text-align: center;
  }
}
`;
